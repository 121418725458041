html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/roboto-v29-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('./fonts/roboto-v29-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/roboto-v29-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('./fonts/roboto-v29-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/roboto-v29-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('./fonts/roboto-v29-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* Customize the default scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #0000001a;
  border-radius: 1000px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #0000004d;
  border-radius: 1000px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #0000004d;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}
